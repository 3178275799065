@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

.rdrCalendarWrapper {
    background-color: transparent;
    font-size: 1.2rem;
}

.rdrCalendarWrapper, .rdrDayNumber span, .rdrMonthAndYearPickers select, .rdrMonthName {
    color: #F0EFEF;
}
.rdrMonthAndYearPickers select option {
    background-color: #1F1F1F;
}

.rdrMonthAndYearPickers select {
    font-weight: bold;
    padding: 1rem 1.5rem 1rem 1rem;
}

.rdrDayPassive {
    opacity: 0.56;
}

.rdrWeekDays {
    margin-bottom: 0.5em;
}

.rdrWeekDay {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    color: #F0EFEF;
    opacity: 0.56;
}

.rdrWeekDay, .rdrDay {
    width: 3.25rem;
    height: 2.2rem;
}

.rdrMonth {
    width: 22.75rem;
    padding: 0;
}

.rdrDateDisplayWrapper {
    display: none;
}

/* Selection */
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    top: 0.1rem;
    bottom: 0.1rem;
}

/*
 * Caret buttons.
 */
.rdrNextPrevButton {
    background: none;
    width: 1.2rem;
    height: 1.2rem;
}

.rdrPprevButton {
    margin-right: 0;
}

.rdrNextButton {
    margin-left: 0;
}

.rdrNextPrevButton:hover {
    background: none;
}

.rdrPprevButton i {
    border-width: 0.45em 0.6em 0.45em 0.3em;
    border-right-color: #F0EFEF;
}

.rdrNextButton i {
    border-width: 0.45em 0.3em 0.45em 0.6em;
    border-left-color: #F0EFEF;
}

.rdrPrev i {
}

/** Add distance horizontal between calendar months */
.rdrMonthsHorizontal .rdrMonth + .rdrMonth {
    margin-left: 5rem;
}
