.noUi-horizontal {
    height: 2px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: #959494;
}

.noUi-connects {
    overflow: visible;
}

.noUi-connect {
    background-color: #009edd;
    top: -2px;
    border: 0;
    height: 4px;
}

.noUi-horizontal .noUi-handle {
    width: 20px;
    height: 20px;
    top: -10px;
    right: -10px;
    border-radius: 50%;
}
.noUi-handle:before, .noUi-handle:after {
    display: none;
}
