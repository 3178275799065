body {
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  width: .5em;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: #BDBDBD;
  outline: 1px solid slategrey;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #1e78be;
}

.link-like {
  font-weight: bold;
  cursor: pointer;
}

.link-like:hover {
  color: #F0EFEF;
}

html, body, #root, .App {
  height: 100%;
}

html {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #9A9999;
  background-color: #454446;
}

@media (max-width: 992px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 9px;
  }
}

.clickable {
  cursor: pointer;
}

.link-style {
  color: #1e78be;
  cursor: pointer;
}

.link-style:hover {
  text-decoration: underline;
}

h3 {
  font-size: 1.5rem;
}

a {
  color: inherit;
  text-decoration: none;
}
